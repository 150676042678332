<template>
  <div class="login-container">
    <b-row>
      <b-col md="12">
        <div class="basic-1 title-1-bold-24 d-flex"
             style="justify-content: center; align-items: center; margin-bottom: 40px">
          {{ $t("AUTH.CREATE_MENTOR_ACCOUNT") }}
        </div>
      </b-col>
      <b-col md="6" class="mb-5 first-col">
        <GoogleLogin
            class="btn btn-light login-with-google w-100"
            :params="{client_id: '528432422488-2f03lscvhq6j3d7anmsnh7tq4vtu0il5',
                 scope: 'profile email',
                plugin_name:'Edneos'}"
            :onSuccess="registerWithGoogle"
            :onFailure="onFailure">
          <img src="/media/google.svg" alt="">
          {{ $t('AUTH.CREATE_MENTOR_ACCOUNT_WITH_GOOGLE') }}</GoogleLogin>
        <div class="create-account">
          <div class="basic-1" style="font-size: 12px; margin-bottom: 12px">
            {{ $t("AUTH.ALREADY_HAVE_ACCOUNT") }}
          </div>
          <router-link
            :to="{ path: '/auth/login' }"
            class="btn btn-primary"
            style="width: 100%"
          >
            {{ $t("AUTH.LOGIN_BUTTON") }}
          </router-link>
        </div>
      </b-col>
      <b-col md="6" class="mb-5">
        <form
            style="position: relative"
          class="form sh-auth-form"
          novalidate="novalidate"
          id="sh_login_form"
        >
          <div class="form-floating mb-4">
            <input type="text"
                   class="form-control"
                   v-model="model[fields.email.name]"
                   :id="fields.email.name"
                   :placeholder="fields.email.label">
            <label for="floatingInput">{{ fields.email.label }}</label>
          </div>
          <div class="form-floating mb-4">
            <input type="text"
                   class="form-control"
                   v-model="model[fields.full_name.name]"
                   :id="fields.full_name.name"
                   required="true"
                   :placeholder="fields.full_name.label">
            <label for="floatingInput">{{ fields.full_name.label }}</label>
          </div>
          <div
              v-popover:password
              v-popover:info.left
              v-popover.left="{ name: 'password' }"
              class="form-floating mb-4" style="position: relative" :key="showPassword">
            <input v-if="showPassword" type="text"
                   :class="'form-control ' + (this.valid ? '' : 'input-invalid')"
                   v-model="model[fields.password.name]"
                   :id="fields.password.name"
                   :placeholder="fields.password.label"
                   v-b-visible="true"
                   v-on:change="handleChangePassword"
            >
            <input v-else type="password"
                   :class="'form-control ' + (this.valid ? '' : 'input-invalid')"
                   v-model="model[fields.password.name]"
                   :id="fields.password.name"
                   :placeholder="fields.password.label"
                   v-b-visible="true"
                   v-on:change="handleChangePassword"
            >
            <label for="floatingPassword">{{ fields.password.label }}</label>

            <div class="control">
              <unicon @click="() => showPassword = !showPassword"
                      :name="showPassword ? 'eye-slash' : 'eye'" fill="#9A9FA7"/>

            </div>
          </div>

          <popover name="password" position="left" :key="this.model.password">
            <div class="rs-tooltip-inner">
              <h6 style="font-size: 0.9rem;">
                {{ $t('GENERAL.PASSWORD_MUST_CONTAIN') }}</h6>
              <div class="criteria" style="display: flex; padding-top: 5px;">
                <unicon :name="passwordLength ? 'check' : 'multiply'"
                        width="16" height="16" :fill="passwordLength ? 'rgb(5, 183, 10)'
                               : 'rgb(212, 40, 4)'"></unicon>
                <p>{{ $t('GENERAL.AR_LEAST_6_CHARACTERS') }}</p></div>
              <div class="criteria" style="display: flex; padding-top: 5px;">
                <unicon :name="containsLetter ? 'check' : 'multiply'"
                        width="16" height="16" :fill="containsLetter ? 'rgb(5, 183, 10)'
                               : 'rgb(212, 40, 4)'"></unicon>
                <p>{{ $t('GENERAL.AR_LEAST_ONE_LETTER') }}</p></div>
              <div class="criteria" style="display: flex; padding-top: 5px;">
                <unicon :name="containsNumbers ? 'check' : 'multiply'"
                        width="16" height="16" :fill="containsNumbers ? 'rgb(5, 183, 10)'
                               : 'rgb(212, 40, 4)'"></unicon>
                <p>{{ $t('GENERAL.AR_LEAST_ONE_NUMBER') }}</p></div>
              <div class="criteria" style="display: flex; padding-top: 5px;">
                <unicon :name="containsSymbols ? 'check' : 'multiply'"
                        width="16" height="16" :fill="containsSymbols ? 'rgb(5, 183, 10)'
                               : 'rgb(212, 40, 4)'"></unicon>
                <p>{{ $t('GENERAL.AR_LEAST_ONE_TOKEN') }}</p></div>
            </div>
          </popover>
          <div class="form-floating mb-4" style="position: relative" :key="showPasswordConfirm">
            <input v-if="showPasswordConfirm" type="text"
                   :class="'form-control ' + (this.validConfirm ? '' : 'input-invalid')"
                   v-model="model[fields.password_confirm.name]"
                   :id="fields.password_confirm.name"
                   :placeholder="fields.password_confirm.label"
                   v-b-visible="true"
                   v-on:change="handleChangePasswordConfirm"
            >
            <input v-else type="password"
                   :class="'form-control ' + (this.validConfirm ? '' : 'input-invalid')"
                   v-model="model[fields.password_confirm.name]"
                   :id="fields.password_confirm.name"
                   :placeholder="fields.password_confirm.label"
                   v-b-visible="true"
                   v-on:change="handleChangePasswordConfirm"
            >
            <label for="floatingPassword">{{ fields.password_confirm.label }}</label>

            <div class="control">
              <unicon @click="() => showPasswordConfirm = !showPasswordConfirm"
                      :name="showPasswordConfirm ? 'eye-slash' : 'eye'" fill="#9A9FA7"/>

            </div>
          </div>
          <div class="d-flex mb-5">
            <b-form-checkbox
                required
                class="me-2"
                v-model="agreeTerms"
                v-on:change="changeValue"
                value="true"
                unchecked-value="false"
            />
            {{ $t("AUTH.AGREE") }}
            <a
                href="https://edneos.com/terms-and-conditions"
                target="_blank"
                class="text-primary font-size-sm font-weight-bold text-hover-primary ms-1"
            >
              {{ $t("AUTH.TERMS") }}
            </a>
          </div>

          <div class="pb-lg-0 mb-4">
            <base-button
              block variant="secondary"
              :callback="doSubmit" callbackParams='0'>
              <unicon v-if="loading" fill="#333333" height="24" width="24" name="spinner-alt"/>
              <span v-else>{{ $t('AUTH.SING_UP') }}</span>
            </base-button>
          </div>

        </form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserModel from '@/modules/auth/user-model';
import { server } from '@/const';
import Notify from '@/shared/utils/notify';
import GoogleLogin from 'vue-google-login';
import { saveToken } from '@/shared/services/jwt.service';
import Vue from 'vue';
import VPopover from 'vue-js-popover';
import i18n from '@/shared/plugins/vue-i18n';

Vue.use(VPopover, { tooltip: true });
const { fields } = UserModel;
let agreeTerms = false;
export default {
  name: 'CreateAccount',
  components: { GoogleLogin },
  data() {
    return {
      agreeTerms: false,
      componentKey: 0,
      fields,
      model: {},
      showPassword: false,
      showPasswordConfirm: false,
      passwordLength: false,
      containsNumbers: false,
      containsLetter: false,
      containsSymbols: false,
      valid: false,
      validConfirm: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
    }),
    doSubmit() {
      this.handleChangePassword({ target: { value: this.model.password } });
      if (agreeTerms === true) {
        if (this.password !== 0 && (this.valid === false || this.validConfirm === false)) {
          Notify('warning', i18n.t('AUTH.ERROR_PASSWORD'));
          return;
        }
        this.loading = true;
        const loginPath = '/WebRegister.php';
        const data = new FormData();
        data.append('email', this.model.email);
        data.append('name', this.model.full_name ?? '');
        data.append('password', this.model.password);
        data.append('type', 'mentor');
        fetch(server + loginPath, {
          method: 'POST',
          body: data,
        })
          .then((response) => response.json())
          .then((json) => {
            if (json.status === 'error') {
              Notify('warning', json.error);
            } else {
              saveToken(json.token);
              window.localStorage.setItem('role', json.type);
              this.$router.push({ name: 'home' });
            }
            this.loading = false;
          });
      } else {
        Notify('success', 'Agree Terms of Service, Privacy Policy.');
      }
    },
    changeValue() {
      agreeTerms = !agreeTerms;
    },
    registerWithGoogle(googleResponse) {
      this.loading = true;
      const loginPath = '/WebLogin.php';
      const data = new FormData();
      data.append('token', googleResponse.Qc.id_token);
      data.append('provider', 'google');
      /*      data.append('login', googleResponse.xy.oz);
      data.append('name', googleResponse.xy.hg);      */
      data.append('type', 'mentor');
      fetch(server + loginPath, {
        method: 'POST',
        body: data,
      }).then((response) => response.json()).then((json) => {
        if (json.status === 'error') {
          Notify('warning', json.error);
        } else {
          saveToken(json.token);
          window.localStorage.setItem('role', json.type);
          this.$router.push({ name: 'home' });
        }
        this.loading = false;
      });
    },
    onFailure() {
      Notify('warning', i18n.t('ERRORS.DEFAULT_ERROR_MESSAGE'));
    },
    handleChangePassword(e) {
      const number = e.target.value.match(/\d+/g);
      const letters = e.target.value.match(/[a-z]/);
      const symbols = new RegExp(/[^A-Z a-z0-9]/);

      this.passwordLength = e.target.value.length > 7;
      this.containsNumbers = number != null;
      this.containsLetter = letters != null;
      this.containsSymbols = symbols.test(e.target.value);
      this.valid = e.target.value.length > 7 && number != null
          && letters != null && symbols.test(e.target.value);
      this.validConfirm = e.target.value === this.model.password_confirm;
    },
    handleChangePasswordConfirm(e) {
      this.validConfirm = e.target.value === this.model.password;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/auth-layout.scss';

.login-container {
  height: 100%;
  margin: auto;

  .auth-forgot-password a {
    color: #1694D0 !important;
  }
  .control{
    box-sizing: border-box;
    clear: both;
    font-size: 1rem;
    position: absolute;
    text-align: inherit;
    right: 16px;
    top: 20px;
    .button{
      display: flex;
    }
  }
}

.create-account{
  margin-top: 12px
}
.btn.btn-light.login-with-google{
  border: 0!important;
  color: #505161!important;
  font-size: 14px!important;
  display: flex;
  justify-content: center;
  font-weight: normal!important;
  align-items: center;
  img{
    width: 22px;
    height: 22px;
  }
}

.login-container{
@media (min-width: 768px) {
  .col-md-6{
    padding: 0 40px;
  }
  .first-col {
    border-right: 1px solid #E0E0E0;
  }
  .create-account{
    margin-top: 294px
  }

}}
.login-error-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #D66A5B;
  margin-bottom: 4px;
}
.form-control.login-error {
  border: 1px solid #D66A5B!important;
  box-shadow: 2px 2px 4px rgba(166, 171, 189, 0.25);
}
.sh-auth-form{
  .vue-popover{
    left: -200px!important;
    top: 70px!important;
  }
}
</style>
